import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import { neutral } from "../../../../common/config/colors";
import { sliceString } from "../../../../common/utils/utils";

// styles
import styles from "./styles/Card.module.css";
import { DEFAULT_COURSE_CARD_IMAGE } from "../../../../courses/utils/constants";

const Card: React.FC<NSCohort.ICard> = ({
  height = 348,
  children,
  bootcamp,
}) => {
  const router = useRouter();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Link href={`${router.route}/${bootcamp.slug}`}>
      <a className={styles.bootcampDetailLink}>
        <Box
          width={{ xs: 320, sm: 370 }}
          height={height}
          bgcolor={neutral["A500"]}
          boxShadow="0px 4px 43px rgba(0, 0, 0, 0.08)"
          sx={{
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 6px 97px 0px rgba(0, 0, 0, 0.08)",
            },
          }}
          borderRadius="20px"
          display="flex"
          flexDirection="column"
        >
          <Image
            src={bootcamp?.card_image || DEFAULT_COURSE_CARD_IMAGE}
            alt="python"
            width={isDownSm ? 320 : 370}
            height={isDownSm ? 178 : 180}
            className={styles.bootcampCardImage}
          />

          <Box pt={4} px={1} title={bootcamp.name}>
            <Typography pl={4} pr={8} height={56} fontWeight={600} variant="h5">
              {sliceString(bootcamp.name, 40)}
            </Typography>
          </Box>
          {children}
        </Box>
      </a>
    </Link>
  );
};

export default Card;
