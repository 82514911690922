import React from "react";

import CohortSection from "./CohortSection";
import EmptyPlaceholder from "./EmptyPlaceholder";

const Cohort: React.FC<NSCohort.ICohorts> = ({ cohorts }) => {
  const activeCohorts = cohorts?.filter((cohort) => cohort.status === "active");
  const upcomingCohorts = cohorts?.filter(
    (cohort) => cohort.status === "upcoming"
  );

  return (
    <>
      <div id="exploreBootcamps">
        {!!activeCohorts &&
          !!upcomingCohorts &&
          activeCohorts.length == 0 &&
          upcomingCohorts.length == 0 && <EmptyPlaceholder />}
      </div>

      {activeCohorts.length != 0 && (
        <div id="exploreBootcamps">
          {!!activeCohorts && (
            <CohortSection type="active" cohorts={activeCohorts} />
          )}
        </div>
      )}
      {upcomingCohorts.length != 0 && (
        <div id="exploreBootcamps">
          {!!upcomingCohorts && (
            <CohortSection type="upcoming" cohorts={upcomingCohorts} />
          )}
        </div>
      )}
    </>
  );
};

export default Cohort;
