import React from "react";
import Link from "next/link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme, useMediaQuery } from "@mui/material";

import Section from "../../../../common/components/layout/utils/Section";
import GenericError from "@packages/common/src/components/error-handlers/GenericError";
import { neutral } from "../../../../common/config/colors";
import { FRONTEND_COURSES_URL } from "../../../../common/utils/constants";

const EmptyPlaceholder = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const imageSrc = isDownSm
    ? "/bootcamps/mobile-emptyplaceholder.png"
    : "/bootcamps/desktop-emptyplaceholder.png";

  const props: NSCohort.IEmptyPlaceholder = {
    heading: "There are no active bootcamps.",
    description:
      "Please visit us later or check our other community initiatives",
    image: imageSrc,
  };

  return (
    <Section
      boxProps={{
        py: { xs: 8, md: 12 },
        bgcolor: neutral["A500"],
      }}
    >
      <Box m="auto">
        <GenericError
          heading={props.heading}
          description={props.description}
          imageUrl={props.image}
          imageProps={{
            height: isDownSm ? 136 : 170,
            width: isDownSm ? 158 : 300,
          }}
        />
        <Box display="flex" justifyContent="center">
          <Link href={FRONTEND_COURSES_URL} passHref>
            <Button variant="contained" color="primary">
              Explore Courses
            </Button>
          </Link>
        </Box>
      </Box>
    </Section>
  );
};

export default EmptyPlaceholder;
