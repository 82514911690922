import dayjs, { OpUnitType, QUnitType } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export const getDayjsDate = (props: { date: string; format: string }) => {
  return dayjs(props.date).tz(dayjs.tz.guess()).format(props.format);
};

/**
 * Converts the date to UTC format according to timezone
 * If you're using getDayjsDate to show native datetime-local format,
 * you must use this function when sending the request data to backend because
 * it first converts the date according to timezone then utc
 * */
export const getUTCDate = (date: string) => {
  return dayjs.tz(date, dayjs.tz.guess()).utc();
};

export const getTimeLeft = (endTime: string, unit?: QUnitType | OpUnitType) =>
  dayjs(endTime).diff(dayjs(), unit);

export const isTimelineActive = (date: string) =>
  Date.parse(date) - Date.parse(new Date().toISOString()) > 0;

export const getTimelineStatus = (
  startDate: string,
  endDate: string
): "ongoing" | "upcoming" | "ended" => {
  let status: "ongoing" | "upcoming" | "ended" = "upcoming";

  if (isTimelineActive(startDate)) {
    status = "upcoming";
  } else if (!isTimelineActive(startDate) && isTimelineActive(endDate)) {
    status = "ongoing";
  } else {
    status = "ended";
  }
  return status;
};

export const getDifferenceInMins = (startDate: string, endDate: string) => {
  const diff =
    (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;
  return Math.abs(diff / 60);
};

export const getTotalMinutes = ({
  days,
  hours,
  minutes,
}: {
  days: unknown;
  hours: unknown;
  minutes: unknown;
}) => {
  const MINUTES_IN_HOUR = 60;
  const HOURS_IN_DAY = 24;

  let totalMinutes = 0;
  if (days) {
    totalMinutes += parseInt(days as string) * HOURS_IN_DAY * MINUTES_IN_HOUR;
  }
  if (hours) {
    totalMinutes += parseInt(hours as string) * MINUTES_IN_HOUR;
  }

  if (minutes) {
    totalMinutes += parseInt(minutes as string);
  }

  return totalMinutes;
};

export const getDurationString = (mins: number): string => {
  var minutes = Math.floor(mins);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - days * HOURS_IN_DAY;
  minutes =
    minutes - days * HOURS_IN_DAY * MINUTES_IN_HOUR - hours * MINUTES_IN_HOUR;

  let dDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "";
  let hDisplay = hours > 0 ? hours + (hours === 1 ? " hr" : " hrs") : "";
  let mDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? " min" : " mins") : "";

  return `${dDisplay} ${hDisplay} ${mDisplay}`;
};

export const getDate = (date: string) => dayjs(date).tz(dayjs.tz.guess());

export const getTimezone = () => {
  return dayjs.tz.guess();
};
