import { SERVER } from "../../common/config/utils";

export const COURSES_LIST_PAGE_HEAD_SECTION_IMAGE =
  "/courses/courses-head-section.svg";
export const COURSES_LIST_PAGE_LOGOS = "/courses/courses-desktop-logos.svg";
export const LEARNING_TRACKS_IMAGE = "/courses/learning-tracks.svg";
export const DEFAULT_COURSE_CARD_IMAGE = "/courses/default-course-card.png";
export const INTUITIVE_CONTENT_IMAGE = "/courses/intuitive-content.svg";
export const STRUCTURED_LEARNING_IMAGE = "/courses/structured-learning.svg";
export const REAL_WORLD_CHALLENGES_IMAGE = "/courses/real-world-challenges.svg";
export const COURSE_LIST_PAGE_OG_IMAGE = `${SERVER}/courses/course-list-page-og-image.png`;
export const COURSES_MATERIAL_IMAGE = "/courses/course-material-icon.svg";
export const HERO_SECTION_BACKGROUND_IMAGE = "/courses/waves.png";
export const COURSES_PAGE_HEADER_IMAGE =
  "/courses/courses-list-page-header-image.svg";

export const ALUMNI_SECTION_DESKTOP_LOGOS =
  "/courses/alumni-section-desktop-image.png";
export const COURSE_CERTIFICATE_IMAGE = "/courses/course-certificate.png";

export const COURSE_PAGE_LINKEDIN_URL = `https://www.linkedin.com/shareArticle?mini=true&url=${
  typeof window !== "undefined" ? window.location.href : "https://aiplanet.com/"
}?utm_source=course_page&utm_medium=linkedin&utm_campaign=rp`;
export const COURSE_PAGE_FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${
  typeof window !== "undefined" ? window.location.href : "https://aiplanet.com/"
}?utm_source=course_page&utm_medium=facebook&utm_campaign=rp`;

export const COURSES_URL = "/courses/";
export const ACHIEVEMENTS_URL = "/dashboard/achievements/";

export const COURSES_ENROLLED = "/courses/landing-page/main/";
