import { Fragment, useCallback } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useTheme, useMediaQuery } from "@mui/material";

import Card from "./Card";
import Section from "../../../../common/components/layout/utils/Section";
import Timer from "../../common/Timer";
import { getDayjsDate } from "../../../../common/utils/dayjsUtils";

const BootcampTimeline: React.FC<
  Pick<NSCohort.ICohort, "start_date" | "end_date"> & { height?: number }
> = ({ start_date, end_date, height }) => (
  <Box display="flex" pl={4} alignItems="center" columnGap={1} height={height}>
    <DateRangeIcon fontSize="small" htmlColor="#333333" />
    <Typography fontSize="14px" fontWeight={400}>
      {getDayjsDate({
        date: start_date,
        format: "Do MMM",
      })}{" "}
      -{" "}
      {getDayjsDate({
        date: end_date,
        format: "Do MMM",
      })}
    </Typography>
  </Box>
);

const CohortSection: React.FC<NSCohort.ICohortSection> = ({
  type,
  cohorts,
}) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Section
      boxProps={{
        py: { xs: 8, md: 12 },
        bgcolor: type === "upcoming" ? "#F8F9FD" : "",
      }}
    >
      <>
        {type === "active" && cohorts.length !== 0 && (
          <Box pb={{ xs: 6, md: 12 }}>
            <Typography
              fontWeight={600}
              variant="h3"
              textAlign="center"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: 24,
                },
              }}
            >
              Active Bootcamps
            </Typography>
            <Typography
              fontWeight={500}
              variant="h6"
              pt={4}
              color="3B454E"
              textAlign="center"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              Embrace the challenge of completing the lessons and growing
              professionally in your data science journey!
            </Typography>
          </Box>
        )}

        {type === "upcoming" && cohorts.length !== 0 && (
          <Box pb={{ xs: 6, md: 12 }}>
            <Typography
              fontWeight={600}
              variant="h3"
              textAlign="center"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: 24,
                },
              }}
            >
              Upcoming Bootcamps
            </Typography>
            <Typography
              fontWeight={500}
              variant="h6"
              pt={4}
              color="3B454E"
              textAlign="center"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              Embrace the challenge of completing the lessons and growing
              professionally in your data science journey!
            </Typography>
          </Box>
        )}

        <Grid container spacing={8} display="flex" px={1}>
          {cohorts.map((cohort) => (
            <Fragment key={cohort.id}>
              {cohort.bootcamps.map((bootcamp) => (
                <Grid
                  key={bootcamp.id}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  xl={4}
                  display="flex"
                  justifyContent="center"
                >
                  <Card
                    height={
                      type === "upcoming"
                        ? isDownSm
                          ? 475
                          : 423
                        : isDownSm
                        ? 327
                        : 327
                    }
                    bootcamp={bootcamp}
                  >
                    {type === "upcoming" ? (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <BootcampTimeline
                            start_date={cohort.start_date}
                            end_date={cohort.end_date}
                          />
                        </Box>
                        <Typography
                          color="#444444"
                          fontSize="14px"
                          fontWeight={500}
                          pl={5}
                          pt={8}
                          pb={4}
                        >
                          STARTS ON
                        </Typography>
                        <Box px={4}>
                          <Timer date={cohort.start_date} />
                        </Box>
                      </>
                    ) : (
                      <BootcampTimeline
                        start_date={cohort.start_date}
                        end_date={cohort.end_date}
                      />
                    )}
                  </Card>
                </Grid>
              ))}
            </Fragment>
          ))}
        </Grid>
      </>
    </Section>
  );
};

export default CohortSection;
