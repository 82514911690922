import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';

import useTimeLeft from "@packages/skillspace/src/common/components/use-timeLeft/useTimeLeft";

const Timer: React.FC<{ date: string }> = ({ date }) => {
  const { timeLeft } = useTimeLeft(date, 1000);

  const data = [
    {
      id: 1,
      title: timeLeft.days,
      subtitle: "Days",
    },
    {
      id: 2,
      title: timeLeft.hours,
      subtitle: "Hrs",
    },
    {
      id: 3,
      title: timeLeft.minutes,
      subtitle: "Mins",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      {data.map(({ id, title, subtitle }) => (
        <Box
          key={id}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              height: 46,
              width: 46,
              display: "flex",
              placeItems: "center",
            }}
          >
            <Typography variant="h5" color="#F44336" fontWeight={500} mx={3}>
              {title}
            </Typography> 
            <Divider orientation="vertical" variant="middle" flexItem />
          </Box>
          <Typography variant="body2">{subtitle}</Typography>
        </Box>
      ))}
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              height: 46,
              width: 46,
              display: "flex",
              placeItems: "center",
            }}
          >
            <Typography variant="h5" color="#F44336" fontWeight={500} mx={3}>
              {timeLeft.seconds}
            </Typography> 
          </Box>
          <Typography variant="body2">Mins</Typography>
        </Box>
    </Box>
  );
};

export default Timer;
