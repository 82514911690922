import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { IEndTime, ITimeLeft } from "./types";

const calculateTimeLeft = (endTime: IEndTime) => dayjs(endTime).diff(dayjs());

const IsLessThanTen = (value: number) =>
  value < 10 ? `0${value}` : `${value}`;

const useTimeLeft = (endTime: IEndTime, intervalTime: number) => {
  const [isActive, setIsActive] = useState(true);
  const [totalTimeLeft, setTotalTimeLeft] = useState(
    !!endTime ? calculateTimeLeft(endTime) : 0
  );

  useEffect(() => {
    if (totalTimeLeft > 0 && !!endTime) {
      const timer = setInterval(() => {
        setTotalTimeLeft(calculateTimeLeft(endTime));
      }, intervalTime);
      // Clear timeout if the component is unmounted
      return () => clearInterval(timer);
    } else {
      setIsActive(false);
    }
  }, [totalTimeLeft, endTime, intervalTime]);

  const timeLeft: ITimeLeft = {
    seconds: IsLessThanTen(Math.floor((totalTimeLeft / 1000) % 60)),
    minutes: IsLessThanTen(Math.floor((totalTimeLeft / 1000 / 60) % 60)),
    hours: IsLessThanTen(Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24)),
    days: IsLessThanTen(Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24))),
    toString() {
      return `${this.days}:${this.hours}:${this.minutes}:${this.seconds}`;
    },
  };

  return { timeLeft, isActive };
};

export default useTimeLeft;
